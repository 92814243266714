import { LeapNewsItemType } from "components/LSRevamp/LSRevampInterfaces"
import LeapInNewsCard from "components/LSRevamp/common/LeapInNewsCard"
import { leapNewsItems } from "components/LSRevamp/constant"
import withWindowDimensions from "components/common/withWindowDimensions"
import useDevice from "hooks/useDevice"
import Slider, { Settings } from "react-slick"
import styled from "styled-components"

const LeapInNewsSection = () => {
  const { isMobile, isTablet } = useDevice()
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
  }

  return (
    <div className="py-8 md:px-4 md:max-w-[1200px] md:m-auto md:py-10">
      <h2 className="font-bold text-2xl pb-4 md:text-4xl md:pb-6 text-center">
        Leap in the News!
      </h2>
      <StyledSlider {...settings}>
        {leapNewsItems.map((item: LeapNewsItemType, index: number) => {
          return (
            <LeapInNewsCard
              key={index}
              backgroundColorClass={item?.backgroundColor}
              content={item?.content}
              logo={item?.logo}
              width={item?.width}
              height={item?.height}
              mobileSizes={item?.mobileSizes}
            />
          )
        })}
      </StyledSlider>
    </div>
  )
}

export default withWindowDimensions(LeapInNewsSection)

const StyledSlider = styled(Slider)`
  .slick-dots {
    position: absolute;
    bottom: -35px;
  }
  .slick-dots > li {
    background: #bdbdbd !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 100% !important;
    &.slick-active {
      background: #757575 !important;
    }
    & > button:before {
      font-size: 0 !important;
    }
  }
`
