import React from "react";
import Image from "next/image";
import withWindowDimensions from "components/common/withWindowDimensions";

interface Props {
  backgroundColorClass: string;
  logo: string;
  content: string;
  height: number;
  width: number;
  isMobileSized?: boolean;
  mobileSizes: any;
}

const LeapInNewsCard = ({
  backgroundColorClass,
  logo,
  content,
  width,
  height,
  isMobileSized,
  mobileSizes,
}: Props) => {
  return (
    <div className="w-[231px] md:w-[354px] rounded-lg shadow-19 pb-6 mb-1 m-auto">
      <div
        className={`${backgroundColorClass} h-14 md:h-16 rounded-t-lg flex items-center pl-3 pt-2`}
      >
        <div className="mix-blend-darken">
          <Image
            src={logo}
            alt="logo"
            width={isMobileSized ? mobileSizes?.width : width}
            height={isMobileSized ? mobileSizes?.height : height}
          />
        </div>
      </div>
      <p className="font-bold text-base w-11/12 p-3 md:p-5 md:w-9/12 h-24">
        {content}
      </p>
    </div>
  );
};

export default withWindowDimensions(LeapInNewsCard);
